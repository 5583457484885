import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Header from '../components/header';
import Hero from '../components/Hero';
import AboutImages from '../components/AboutImages';
import { ContentContainer, Container, StyledFlex } from '../components/layoutComponents';
import SEO from "../components/seo";
import { keywords } from '../constants/Keywords';



const About = ({ data, location }) => {
    const sources = [
        {
          ...data.sunsetDesktop.childImageSharp.fluid,
          media: `(min-width: 600px)`
        },
        data.sunsetMobile.childImageSharp.fluid
    ]

    return (
    <React.Fragment>
        <SEO title="About Us" keywords={keywords} />
        <Header path={location.pathname} />
        <Container>
            <section>
                <ContentContainer>
                    <h1 style={{ textAlign: 'center', fontWeight: '400', fontFamily: '"Amatic SC", cursive', fontSize: '4.5rem', margin: '8rem 0'}}>History in Making</h1>
                    <h1 style={{ textAlign: 'center', margin: '2rem 0' }}>Below is my fathers clear-eyed manifesto on alternative housing and how 
                    domes are changing the world for the better. </h1>
                    
                    <p>On July 5th of 2020 he passed away in his home surrounded by family. Amongst the many dreams a dreamer of his caliber could dream, there was one I know that was dear to him - that his family help in whatever way possible to carry on his work with domes, <strong>an important part of his legacy</strong>; to continue to spread the Gospel (or the only Gospel he knew) about the myriad benefits of domes.</p>
                </ContentContainer>
                <StyledFlex>
                    <Img 
                        fluid={data.dome_model.childImageSharp.fluid}
                        style={{
                            marginRight: '1rem',
                            marginBottom: '1rem',
                            width: '100%'
                        }}
                    />
                    <p id="lastChild">It was my fathers belief that domes weren’t just about the structure, but about challenging our old maps, desperately outdated for the times. He knew that not everyone wanted to live in a dome, but he wanted us all to consider that there just might be another way to think about our homes, and therefore our lives, on this planet. </p>
                </StyledFlex>
            </section>
        </Container>
        <Hero sources={sources}>
            <h2 style={{ color: '#336699', textAlign: 'center', margin: '0' }}>If you can imagine change on such a grand scale, as my father did daily, then what can be accomplished, or more to the point - what can’t be accomplished?</h2>
        </Hero>
        <Container>
                <section>
                    <ContentContainer>
                        <p style={{ margin: '-2rem 0 0' }}>Above, I chose to say “how domes <strong>are</strong> changing the world for the better”, as opposed to “how domes <strong>could</strong> change the world for the better.” This was an act of concious choice. My father knew the hurdles to mass adoption of domes on this planet. He was so passionate about what domes could do for humanity. </p>
                    </ContentContainer>
                    <StyledFlex>
                        <h3 style={{ margin: '0', color: '#316191', textAlign: 'center' }}>However, in the last almost 15 years, I was lucky to have the chance to watch my dad build not just domes, but an entire community of folks from around the world - and I truly mean around the world. People interested in the change that these structures can bring to their lives and the planet.</h3>
                        <div id="lastChild">
                            <Img 
                                fluid={data.dome1.childImageSharp.fluid}
                                style={{
                                    marginRight: '1rem',
                                    marginBottom: '1rem',
                                    width: '100%'
                                }}
                            />
                            <p style={{ margin: '0', fontSize: '.8rem', lineHeight: '20px' }}>Robert Lassiter (left), cousin 
                            Ric (center), and brother Tommy (right); taken in 1974 during my fathers first dome 
                            project. A greenhouse dome built with his two brothers and his cousin.</p>
                        </div>
                    </StyledFlex>
                    <ContentContainer padding="0 0 2rem">
                        <h1 style={{ textAlign: 'center' }}>In my fathers life, domes <strong>did</strong> change the world for the better.</h1>
                        
                        <p>He connected with people from different walks of life on an almost daily basis - all people that were hungry for the same change as him. In this final chapter of his life, he found a new pack, one for which I know he was grateful.</p>
                    </ContentContainer>
                </section>
            </Container>
            <div style={{ backgroundColor: '#3ea5ce', paddingTop: '3rem' }}>
                <Container>
                <ContentContainer margin="0 auto" padding="0 0 2rem">
                <section>
                <article style={{ color: 'white' }}>
                    <h1 style={{ fontSize: "2.85rem", fontFamily: "'PT Serif', serif", color: 'white', marginBottom: '.5rem' }}>Domes and the Way Forward</h1>
                    <p>written by Robert Lassiter</p>
                    <p>I have observed that for the most part, all we know of shelter revolves around our current knowledge of compressive buildings. A compressive building is one that handles loads in a more or less vertical direction. This allows for the reasons for massive foundations and overbuilt roofs that can be at a disadvantage when compressive forces are combined with unexpected natural weather forces.</p>
                    
                    <p>We are a stubborn species when it comes to our preferences in shelter much as birds have their taste for one particular kind of nest. That preference is mixed into our DNA and at some time in our historical record it probably served us well. With an unpredictable climate that we have perhaps helped to alter, things have changed. Over population which stresses the finite resources that are available for housing and the lack of fortune that many find themselves in, have forced us to contemplate a different future. Larger congregations of people and ever more environmental assaults on a fragile ecosystem are calling us to a new understanding of sustainable living.</p>
                    
                    <p>Ironically we have more superior components in which to build our homes. If we can lean into a different mindset, we can easily do more with less…and do it better, easier, safer, and more creative and comfortable than ever imagined.</p>
                    
                    <p>Geodesic Domes are one such option that meets many of the criterions afore mentioned. Since their initial introduction into the real world, technology has helped make them practical to build and weatherproof. Elastomeric coatings, i.e. HydroStop, with its foundation system with fiber cloth embedded in the application, has completely erased the myth that domes are hard and expensive to seal. With years in the field, it has held up without checking or cracking and can be easily coated onto the surface of a dome with manual brushes or rollers. It comes in an infinite array of colors. It is easily top-coated about every 10-15 years to make up for the UV degradation all such products experience.</p>
                    
                    <p>The surface area of a dome takes approximately 1/3 less material to cover than a conventional compressive house with the same square footage.  By the design feature of a sphere, it is 30% more energy efficient than the same size compressive house.  Hurricane wind forces and even some tornado forces are better handled by the spherical, tension loaded design than compressive houses. Since geodesic domes are under tension for about 90% of the structure and only 10% under compressive strength around the perimeter at the base, they are seismographically perfect. A dome would not suffer earthquake destruction. When correctly built and balanced between it wanting to fall in and wanting to blow apart, it is a formidable construction.</p>
                    
                    <p>This proof of concept has been demonstrated and is accepted and verified. Now it is time for architects and engineers to bring to bear their expertise to help make dome living an excepted lifestyle. Domes at present are too good to be true and therein lies their problem. The fact that some domes are not constructed properly makes these initial steps more laborious. Just think how many houses are not constructed properly and it does not seem a fatal blow to domes. While I concede that all of our work is ahead of us in this regard, I am heartened to remember what a job mobile home manufacturers had and still have in selling the idea of their homes.</p>
                    
                    <p>I spent 40 years as a horticulturist in greenhouse plant production. I have spent my encore profession refining an all wooded dodecahedron dome so that it not only can be erected by low skilled carpenters, but by people with no skill as a carpenter. It contains no exotic materials or bolts. It is easily erected with wooden dowels and although I am hesitant in saying how fast it goes up, it can take as little as two days with a crew of 2 persons. The heaviest component of a 36 foot dome structure weighs less than 10 pounds. Templates for the skin, or covering, make coverage as easy as tracing out 3 different size triangles and cutting and screwing them to the structure. Everything is color coated red, green, and blue.</p>

                    <p>I have built domes since 1974 and continue to find extraordinary pleasure in the people who choose to live in these beautiful structures. One can only hope that domes will become a common response in places like Haiti and the Gulf Coast and Chile and Pakistan and China and Africa when sustainable housing is called for. In the mean time, let’s not forget the joy of creative space and how spectacular our minds are at inventing it. Domes are for looking up!</p>
                </article>
                </section>
                </ContentContainer>
            </Container>
            </div>
            <Container>
                <ContentContainer>
                    <StyledSection>
                        <AboutImages data={data} />
                    </StyledSection>
                    <p style={{ fontSize: '.8rem', margin: '0 2rem 2rem' }}>Pictures taken in 1974 during my fathers first dome project. A greenhouse dome
                built with his two brothers and his cousin.</p>
                </ContentContainer>
            </Container>
    </React.Fragment>
    )
}

const StyledSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    > * {
        flex: 0 0 auto;
    }

    @media (max-width: 599px) {
        > * {
            flex: 0 0 100%;
        }
    }
`

export const query = graphql`
    query {
        dome1: file(relativePath: { eq: "about/1.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 100) {
                ...GatsbyImageSharpFluid
                aspectRatio
                }
            }
        }
        dome2: file(relativePath: { eq: "about/2.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
                aspectRatio
                }
            }
        }
        dome3: file(relativePath: { eq: "about/3.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        dome4: file(relativePath: { eq: "about/4.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        dome5: file(relativePath: { eq: "about/5.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        dome_model: file(relativePath: { eq: "about/dome-model-fair_edit.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 100) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                }
            }
        }
        sunsetDesktop: file(relativePath: { eq: "about/sunset.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
                aspectRatio
              }
            }
          }
        sunsetMobile: file(relativePath: { eq: "about/sunset_mobile.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 599, quality: 100) {
                ...GatsbyImageSharpFluid
                aspectRatio
              }
            }
          }
    }
`

export default About;