import React from 'react';
import Img from 'gatsby-image';

const AboutImages = ({ data }) => {
    return (
        <>
            <Img 
                fluid={data.dome2.childImageSharp.fluid}
                style={{
                    width: '300px',
                    marginBottom: '1rem',
                    marginRight: '1rem',
                }}
            />
            <Img 
                fluid={data.dome3.childImageSharp.fluid}
                style={{
                    width: '300px',
                    marginBottom: '1rem',
                    marginRight: '1rem',
                }}
            />
            <Img 
                fluid={data.dome4.childImageSharp.fluid}
                style={{
                    width: '300px',
                    marginBottom: '1rem',
                    marginRight: '1rem',
                }}
            />
            <Img 
                fluid={data.dome5.childImageSharp.fluid}
                style={{
                    width: '300px',
                    marginBottom: '1rem',
                    marginRight: '1rem',
                }}
            />
        </>
    )
}

export default AboutImages;