import React from 'react';
import Img from "gatsby-image";
import styled from 'styled-components';

import { Container } from './layoutComponents';

const Hero = ({ sources, children }) => {
    
    return (
        <div style={{ position: 'relative' }}>
            <ResponsiveImg 
                sources={sources}
                style={{ marginBottom: '4rem' }}
                fluid={sources}
            />
            <StyledOverlay>
                <Container>
                    <Flex>
                        {children}
                    </Flex>
                </Container>
            </StyledOverlay>
        </div>
    )
}

export default Hero;

// desktop image must be first in array !!!

const ResponsiveImg = styled(Img)`
    > div:first-child {
      @media (min-width: 600px) {
        padding-bottom: ${props => `${100 / props.sources[0].aspectRatio}% !important`};
      }
      @media (max-width: 599px) {
        padding-bottom: ${props => props.sources[1] && `${100 / props.sources[1].aspectRatio}% !important`};
      }
    }
`

const StyledOverlay = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
`

const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`